module.exports = {
    appId: '35548edb-b571-4ba2-83a4-a518e3480242',
    authority: 'https://login.microsoftonline.com/e237b710-0a8a-41e8-aa0d-2c51fe9eca57/',
    scopes: [
      "User.Read.All",
      "calendars.read",
      "Group.Read.All",
      "Contacts.Read",
      "Directory.Read.All"
    ]
  };