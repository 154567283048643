import * as React from 'react';
import { Stack, Text, Link, FontWeights, Shimmer, IMessageBarProps, Layer } from "office-ui-fabric-react"
import { MessageBar, MessageBarType, MessageBarButton } from "office-ui-fabric-react"
import { IStyle, Label } from "office-ui-fabric-react"
import { Panel, PanelType } from "office-ui-fabric-react"
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { IPersonaSharedProps, Persona, PersonaSize, PersonaPresence} from "office-ui-fabric-react"
import { IScheme } from 'office-ui-fabric-react' 
import { DefaultButton, IButtonProps, PrimaryButton} from "office-ui-fabric-react"
import { Position } from 'office-ui-fabric-react/lib/utilities/positioning';
import { shallowCompare } from 'shallow-compare';

export default class GlobalStatus extends React.Component<{}> {
    constructor(props){
        super(props);
        this.state={
            time: new Date().toLocaleTimeString(),
            chronos: props.chronos,
            waiting: false,
            globalNotifyStatus: props.globalNotifyStatus,
            globalNotifyContent: props.globalNotifyContent,
            _refresh: props._refresh,

            showPanel: false,
            panelType: this.props.panelType,
            doGlobalUpdate: props.doGlobalUpdate
        };
    }
    hasError = ():Boolean => {
      if( this.state.globalNotifyStatus == MessageBarType.error || 
          this.state.globalNotifyStatus == MessageBarType.blocked 
      ){
        return true;
      }
      else return false;
    }

    public componentDidMount() {
      this.update();
        /*this.state.timer = setInterval(() => {
          this.setState({
            time: new Date().toLocaleTimeString()
          });
        }, 1000);*/
      }
    update = ():void => {

    }
    private _updateGlobalStatus = (status:MessageBarType, content:String): void =>{
        this.setState({
            globalNotifyStatus: status,
            globalNotifyContent: content,
        });
        //console.log("[GSAA]updated annoucements");
    }

    private _hidePanel = ():void => {
        this.setState({
            showPanel: false,
        });
    }

    showPanel = ():void => {
        this.setState({
            showPanel: true,
        });
    }

    render(){
        const {
            globalNotifyStatus, _refresh, globalNotifyContent, time, showPanel, panelType,
            doGlobalUpdate
        } = this.state;
        return(
            //<Stack vertical gap={10} id="GlobalStatus">
            <Panel 
                isOpen={showPanel}
                type={panelType}
                onDismiss={this._hidePanel}
                headerText="本地与服务可用性状态报告"
                closeButtonAriaLabel="Close"
            >
                <Stack vertical>
                    
                    <MessageBar 
                        ref="GlobalMessageBar"
                        messageBarType={globalNotifyStatus} 
                        actions={ 
                        <Stack horizontal padding="10" gap="10">
                            <MessageBarButton onClick={doGlobalUpdate}>刷新</MessageBarButton>
                        </Stack>
                        }
                        >
                        {globalNotifyContent}
                    </MessageBar>
                </Stack>
            </Panel>
            //</Stack>
        );
    }
}