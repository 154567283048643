import React from "react"
import axios from "axios"
import { Stack, Text } from "office-ui-fabric-react"
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react"
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Persona, PersonaSize, PersonaPresence } from "office-ui-fabric-react"
import { Icon } from "office-ui-fabric-react"
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react"
import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from "office-ui-fabric-react"
import { Callout } from "office-ui-fabric-react"
import { getTheme, FontWeights, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import config from '../config/Config';
import { UserAgentApplication } from 'msal';
import { runInThisContext } from "vm";
var graph = require('@microsoft/microsoft-graph-client');

function toHMS(time):any {
        //space:replace space with ' ' or '0'
        var space=" ";
        var arr = Array();
        var t = time;
        arr[0] = Math.floor(t / 3600);
        t = t % 3600;
        arr[1] = Math.floor(t / 60);
        t = t % 60;
        arr[2] = t;
        if (arr[0] < 10) arr[0] = space + arr[0].toString();
        if (arr[1] < 10) arr[1] = space + arr[1].toString();
        if (arr[2] < 10) arr[2] = space + arr[2].toString();
        return arr;
        //        var text="";
        //        if(arr[0]!=0)text+=arr[0].toString() + " 小时 ";
        //        if(arr[1]!=0)text+=arr[1].toString() + " 分钟 ";
        //        if(arr[2]!=0)text+=arr[2].toString() + " 秒 ";
        //        return text;
}

const theme = getTheme();
const styles = mergeStyleSets({
  buttonArea: {
    verticalAlign: 'top',
    //display: 'inline-block',
    //textAlign: 'center'
  },
  callout: {
    maxWidth: 300
  },
  header: {
    padding: '18px 24px 12px'
  },
  title: [
    theme.fonts.xLarge,
    {
      margin: 0,
      color: theme.palette.neutralPrimary,
      fontWeight: FontWeights.semilight
    }
  ],
  inner: {
    height: '100%',
    padding: '0 24px 20px'
  },
  actions: {
    position: 'relative',
    marginTop: 20,
    width: '100%',
    whiteSpace: 'nowrap'
  },
  link: [
    theme.fonts.medium,
    {
      color: theme.palette.neutralPrimary
    }
  ],
  show:{
    display: "block",
  },
  hidden:{
    display: 'none',
  }
});

export default class QueueInfoArea extends React.Component<{}> {
    constructor(props){
        super(props);

        this.state={
          chronos: props.chronos,
          queue: props.queue,
          estimateTime: props.estimateTime,
          _updateGlobalStatus: props._updateGlobalStatus,
          userInfoArea: props.userInfoArea,
          userGroup: props.userGroup,
          username: props.username,
          userid: props.userid,
          isAuthenticated: props.isAuthenticated,
          enableSubmit: false,
          doGlobalUpdate: props.doGlobalUpdate,

          userInQueue: false,

          submitLevel: props.submitLevel,
          submitInProgress: false,
          submitStatus: 0,//0-Null 1-InProgress 2-Success 3-Failed
          lastSubmitError: "",

          isCalloutVisible: false,
          calloutTitle: "请注意",
          calloutText: "你即将提交此请求。",

          isCallout2Visible: false,
          calloutTitle2: "警告：开发中",
          calloutText2: "此功能尚在开发中。如果你不知道你正在做什么，请不要继续。",

          heartbeatUri: props.heartbeatUri,
          heartbeatStatus: false
        };
    }
    private heartbeat = null;
    private initWebSocket = (): void => {
      var App = this;
        try {
            if (typeof MozWebSocket == 'function')
                WebSocket = MozWebSocket;
            if ( this.heartbeat && this.heartbeat.readyState == 1 )
                this.heartbeat.close();
            this.heartbeat = new WebSocket( this.state.heartbeatUri );
            this.heartbeat.onopen = function (evt) {
                //debug("CONNECTED");
                App.setState({
                                heartbeatStatus: true
                        });
            };
            this.heartbeat.onclose = function (evt) {
              App.setState({
                        //        queueLoaded: -1,
                                heartbeatStatus: false
                        });
                        App.state._updateGlobalStatus();
                //debug("DISCONNECTED");
            };
            this.heartbeat.onmessage = function (evt) {
                //console.log( "[WS-HB]Message received :", evt.data );
                //debug( evt.data );
                if(evt.data=="@"){
                  App.update();
                }
            };
            this.heartbeat.onerror = function (evt) {
              console.log( "[WS-HB]Heartbeat error");
              console.log( evt );
              App.setState({
                          //      queueLoaded: -1,
                                heartbeatStatus: false
                        });
                        App.state._updateGlobalStatus();
                //debug('ERROR: ' + evt.data);
            };
        } catch (exception) {
          if(!App.state._updateGlobalStatus)return;
          App.setState({
                          //      queueLoaded: -1,
                                heartbeatStatus: false
                        });
          //alert("warning: WS Connection Went wrong.");
          console.log(exception);
            //            App.state._updateGlobalStatus();
            //debug('ERROR: ' + exception);
        }
    }
    componentDidMount(){
      this.update();
      this.interval = setInterval(() => this.beat(), 5000); 
      this.initWebSocket();
    }
    private cnt=0;
    private beat = (): void => {
        if(this.heartbeat){
          //console.log(this.heartbeat);
          this.heartbeat.send("!");
        }
        this.cnt++;
        if(this.cnt%6==0){this.update();this.cnt=0;}
    }
    private stopWebSocket = (): void => {
        if (this.heartbeat)
            this.heartbeat.close();
    }
    private checkSocket = (): void => {
        if (this.heartbeat != null) {
            var stateStr;
            switch (this.heartbeat.readyState) {
                case 0: {
                    stateStr = "CONNECTING";
                    break;
                }
                case 1: {
                    stateStr = "OPEN";
                    break;
                }
                case 2: {
                    stateStr = "CLOSING";
                    break;
                }
                case 3: {
                    stateStr = "CLOSED";
                    break;
                }
                default: {
                    stateStr = "UNKNOW";
                    break;
                }
            }
            //debug("WebSocket state = " + this.heartbeat.readyState + " ( " + stateStr + " )");
        } else {
            //debug("WebSocket is null");
        }
    }
    _getCurrentInQueueStatement = (): void => {
      var target = this.state.chronos.toString() + "/api/queue?user=" + this.state.username();
      var App = this;
      App.setState({
          userInQueue:true,
      });
      axios.get(target)
      .then(function (response) {
                      App.setState({
                              userInQueue: response.data.result,
                      });
              })
              .catch(function (error) {
                      App.setState({
                              apiStatus: "unknown",
                      });
                      App._errorOccured();
      });
    
    }
    _getQueueAndEstimate = (): void => {
        var target = this.state.chronos.toString() + "/api/queue";
        var App = this;
        App.setState({
                queueLoaded: 0,
                queue: "",
                estimateTime: "",
        });
        axios.get(target)
                .then(function (response) {
                        ////console.log(response.data);
                        var res = response.data;
                        var queueStr: string, timeStr: string;
                        if (res.isEmpty === true) {
                                queueStr = "无。";
                                timeStr = "现在即可开始准备排练。"
                        } else {
                                var queueList = res.queue;
                                var length = queueList.length;
                                let queueE=[];
                                for (var i = 0; i <= length - 1; i++) {
                                        queueStr = "";
                                        queueStr += queueList[i].name + ", ";
                                        var arr = toHMS(queueList[i].time);
                                        queueStr += "预计用时: ";
                                        queueStr += arr[1] + "分" + arr[2] + "秒";
                                        if (queueList[i].prepTime != 0) {
                                                queueStr += ", 其中准备用时";
                                                var arr1 = toHMS(queueList[i].prepTime);
                                                queueStr += arr1[1] + "分" + arr1[2] + "秒";
                                        }
                                        queueStr += '\n';
                                        queueE.push((<div>{queueStr}</div>));
                                }
                                timeStr = res.estimate + " 即可开始准备排练。";
                                queueStr=queueE;
                        }
                        App.setState({
                                queueLoaded: 1,
                                queue: queueStr,
                                estimateTime: timeStr,
                        });
                        App.state._updateGlobalStatus();
                })
                .catch(function (error) {
                                //console.log(error);
                                App.setState({
                                queueLoaded: -1,
                        });
                        App.state._updateGlobalStatus();
                });
                
    }
    update = ():void => {
        this._getCurrentInQueueStatement();
        this._getQueueAndEstimate();
        var submitLevel= this.state.submitLevel();
        this.setState({
          enableSubmit: submitLevel!=='X'
        });
        if(this.state.heartbeatStatus == false){
          this.initWebSocket();
        }
    }
    private _submit = (): void => {
        //alert("not supported yet.");
        if(this.state.submitInProgress){
          console.log("Break:submit in　progress");
          return;
        }
        var App = this;
        var target = this.state.chronos.toString() + "/api/queue/"+App.state.userid();
        this.setState({
          submitStatus: 1,
          submitInProgress: true,
        });
        console.log("[EMSB]submitInProgress:"+this.state.submitInProgress);
        axios.post(target,{
          user: App.state.username(),
          userGroup: App.state.userGroup()
        }).then(function(response){
          //alert("successfully sent POST request to backend.Please check them out int DevTools or backend logs.")
          App.setState({
            submitStatus: 2,
            submitInProgress: false,
          });
          App.update();
        }).catch(function (error) {
          console.log(error);
          App.setState({
            submitStatus: 3,
            submitInProgress: false,
          });
        });
    }
    private _quit = (): void => {
        if(this.state.submitInProgress){
          console.log("Break:submit in　progress");
          return;
        }
        var App = this;
        var target = this.state.chronos.toString() + "/api/queue/" +App.state.userid();
        console.log("[DELETE]target:"+target);
        this.setState({
          submitStatus: 1,
          submitInProgress: true,
        });
        console.log("[EMSB]submitInProgress:"+this.state.submitInProgress);
        axios.delete(target,{
          user: App.state.username(),
          userGroup: App.state.userGroup()
        }).then(function(response){
          //alert("successfully sent POST request to backend.Please check them out int DevTools or backend logs.")
          App.setState({
            submitStatus: 0,
            submitInProgress: false,
          });
          App.update();
        }).catch(function (error) {
          console.log(error);
          App.setState({
            submitStatus: 0,
            submitInProgress: false,
          });
        });
    }
    private _onShowCalloutClicked = (): void => {
      this.setState({
        isCalloutVisible: !this.state.isCalloutVisible
      });
    };
    private _onCalloutDismiss = (): void => {
      this.setState({
        isCalloutVisible: false
      });
    };
    private _onShowCallout2Clicked = (): void => {
      this.setState({
        isCallout2Visible: !this.state.isCallout2Visible
      });
    };
    private _onCallout2Dismiss = (): void => {
      this.setState({
        isCallout2Visible: false
      });
    };

    private submitButton = React.createRef<HTMLDivElement>();
    private quitButton = React.createRef<HTMLDivElement>();
    render(){
        const {
            queue, estimateTime, isAuthenticated, enableSubmit,
            calloutTitle, calloutText, isCalloutVisible,
            calloutTitle2, calloutText2, isCallout2Visible,
            submitInProgress, submitStatus, userInQueue
            } = this.state;
        return(
          <Stack vertical gap={10}>
              <Stack vertical gap={10}>
                  <Text varient="medium">
                          当前已经进入队列的班级/社团有：
                  </Text>
                  <Shimmer isDataLoaded={queue}>
                          <Text variant="medium">{queue}</Text>
                  </Shimmer>
                  <Text variant="medium">
                          现在立刻提交:
                  </Text>
                  <Shimmer isDataLoaded={estimateTime}>
                          <Text variant="medium">{estimateTime}</Text>
                  </Shimmer>
              </Stack>
              <Stack horizontal gap={10}>
                  <div className={styles.buttonArea} ref={this.submitButton}>
                    <PrimaryButton 
                            text={userInQueue?"已在队列中":
                                  enableSubmit?"提交":
                                  "暂无提交权限"} 
                            className="shortButton"
                            onClick={this._onShowCalloutClicked} 
                            disabled={!enableSubmit || userInQueue}  
                    />
                  </div>
                  <Callout
                    gapSpace={0}
                    target={this.submitButton.current}
                    onDismiss={this._onCalloutDismiss}
                    setInitialFocus={true}
                    hidden={!isCalloutVisible}
                    className={styles.callout}
                  >
                    <div className={styles.header}>
                      <p className={styles.title} id={calloutTitle}>
                        {calloutTitle}
                      </p>
                    </div>
                    <div className={styles.inner}>
                      <div className="ms-Callout-content">
                        <p className="ms-Callout-subText" id={calloutText}>
                          {calloutText}
                        </p>
                      </div>
                      <div className={styles.actions} >
                        <PrimaryButton 
                          //text="提交" 
                          //className="shortButton"
                          onClick={this._submit} 
                          disabled={!enableSubmit || submitInProgress || userInQueue}       
                        >{submitInProgress?<Spinner size={SpinnerSize.small}/>:
                            submitStatus==0?'提交':""}
                          {submitStatus==2?<Icon iconName="Accept"/>:
                           submitStatus==3?<Icon iconName="IncidentTriangle"/>:
                           ""}
                          {}
                        </PrimaryButton>
                      </div>
                    </div>
                  </Callout>
                  <div className={styles.buttonArea}  ref={this.quitButton}>
                  <PrimaryButton
                    text="退出队列"
                    disabled={!userInQueue}
                    onClick={this._onShowCallout2Clicked}
                  />
                  </div>
                  <Callout
                    gapSpace={0}
                    target={this.submitButton.current}
                    onDismiss={this._onCallout2Dismiss}
                    setInitialFocus={true}
                    hidden={!isCallout2Visible}
                    className={styles.callout}
                  >
                    <div className={styles.header}>
                      <p className={styles.title} id={calloutTitle2}>
                        {calloutTitle2}
                      </p>
                    </div>
                    <div className={styles.inner}>
                      <div className="ms-Callout-content">
                        <p className="ms-Callout-subText" id={calloutText2}>
                          {calloutText2}
                        </p>
                      </div>
                      <div className={styles.actions}>
                        <PrimaryButton 
                          onClick={this._quit} 
                          disabled={!enableSubmit || submitInProgress || !userInQueue}       
                        >{submitInProgress?<Spinner size={SpinnerSize.small}/>:
                            submitStatus==0?'退出':"."}
                        </PrimaryButton>
                      </div>
                    </div>
                  </Callout>
              </Stack>
          </Stack>
        );
    }
}
