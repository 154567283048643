import React from "react"
import axios from "axios"
import { Stack, Text } from "office-ui-fabric-react"
import { Dialog, DialogType, DialogFooter } from "office-ui-fabric-react"
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Persona, PersonaSize, PersonaPresence } from "office-ui-fabric-react"
import { DefaultButton, PrimaryButton } from "office-ui-fabric-react"
import { Shimmer, ShimmerElementsGroup, ShimmerElementType, MessageBarType } from "office-ui-fabric-react"

import config from '../config/Config';
import { UserAgentApplication } from 'msal';
import { runInThisContext } from "vm";
var graph = require('@microsoft/microsoft-graph-client');

export default class UserInfoArea extends React.Component<{}> {
    constructor(props){
        super(props);

        this.state={
            chronos: props.chronos,
            isUserDetailLoaded: true,
            isDataLoaded: true,
            loginInProgress: false,
            isAuthenticated: false,
            submitLevel: 'X',
            username: "未登录的用户",
            userGroups: [],
            userId: "",
            topUserGroup: 'D0',
            secondaryText: "请登录以获得提交排练的权限",
            tertiaryText: 'D0-仅查看',
            optionalText: '',
            showUnknownPersonaCoin: true,
            imageInitials: 'SY',
            presence: PersonaPresence.offline,
            dialogHidden: true,
            avatar:"https://chronos.beacoworks.tk/blankAvatar.png",
            doGlobalUpdate: props.doGlobalUpdate,
            globalStatus: props.globalStatus
        };
    }
    componentDidMount(){
        this.userAgentApplication = new UserAgentApplication({
        auth: {
          clientId: config.appId,
          authority: config.authority
        },
        cache: {
          cacheLocation: "localStorage",
          //cacheLocation: CacheStorage.LocalStorage,
          storeAuthStateInCookie: true
        }
      });
    
      var userCu = this.userAgentApplication.getAccount();
      this.setState({
        isAuthenticated: (userCu !== null),
        user: {},
        error: null,
      });
      //console.log("[MSAL]User Login status:" + userCu);
      if (userCu) {
        // Enhance user object with data from Graph
        this.getUserProfile();
      }
      this.updateIndicator();
    }
      
    update = ():void => {
        this.userAgentApplication = new UserAgentApplication({
        auth: {
          clientId: config.appId,
          authority: config.authority
        },
        cache: {
          cacheLocation: "localStorage",
          //cacheLocation: CacheStorage.LocalStorage,
          storeAuthStateInCookie: true
        }
      });
    
      var userCu = this.userAgentApplication.getAccount();
      this.setState({
        isAuthenticated: (userCu !== null),
        user: {},
        error: null,
      });
      //console.log("[MSAL]User Login status:" + userCu);
      
      this.updateIndicator();
    }
    login = async():Promise<> => {
        this.setState({
          loginInProgress: true,
        });
        try {
          await this.userAgentApplication.loginPopup(
              {
                scopes: config.scopes,
                prompt: "select_account"
            });
          await this.getUserProfile();
          this.setState({
            isAuthenticated: true,
          });
          this.state.doGlobalUpdate();
        }
        catch(err) {
          var errParts = err.split('|');
          this.setState({
            isAuthenticated: false,
            user: {},
            error: { message: errParts[1], debug: errParts[0] }
          });
          this.setState({
          loginInProgress: false,
        });
        }
        this.setState({
          loginInProgress: false,
        });
      }
      logout = ():void => {
        this.userAgentApplication.logout();
      }
      getAuthenticatedClient = (accessToken):void => {
        // Initialize Graph client
        const client = graph.Client.init({
          // Use the provided access token to authenticate
          // requests
          authProvider: (done) => {
            done(null, accessToken.accessToken);
          }
        });
        return client;
      }
      getUserAvatar = async(accessToken):Promise<> => {
        const client = this.getAuthenticatedClient(accessToken);
        //console.log("[MSAL-Client]user avatar client");
        //console.log(client);  
        client.config.defaultVersion="beta";
        const avatar = await client.api('/me/photo/$value').get();
        return avatar;
      }
      getUserDetails = async(accessToken):Promise<> => {
        var client = this.getAuthenticatedClient(accessToken);
        //console.log("[MSAL-Client]user detail client");
        //console.log(client);  
        const user = await client.api('/me').get();
        return user;
      }
      getuserGroupss = async(accessToken):Promise<> => {
        var client = this.getAuthenticatedClient(accessToken);
        //console.log("[MSAL-Client]user detail client");
        //console.log(client);  
        const user = await client.api("/me/memberOf").get();
        return user;
      } 

      getUserProfile = async():Promise<> => {
        this.setState({
          isUserDetailLoaded: false,
          isDataLoaded: false,
        });
        try {
          // Get the access token silently
          // If the cache contains a non-expired token, this function
          // will just return the cached token. Otherwise, it will
          // make a request to the Azure OAuth endpoint to get a token
      
          var accessToken = await this.userAgentApplication.acquireTokenSilent({
            scopes: config.scopes
          });
          //console.log("[MSAL-Access]Token Acquired");
          //console.log(accessToken);
          if (accessToken) {
            // Get the user's profile from Graph
            var user = await this.getUserDetails(accessToken);
            //console.log("[MSAL-Auth]User information as follows:");
            //console.log(user);
            this.setState({
              isAuthenticated: true,
              user: {
                displayName: user.displayName,
                email: user.mail || user.userPrincipalName,
              },
              error: null,
              username: user.displayName,
              userId: user.id,
              secondaryText: user.mail || user.userPrincipalName,
              //tertiaryText: "权限:"+user.jobTitle,
            });
          }
        }
        catch(err) {
            //console.log("[MSAL-Auth]Error occured!");
            //console.log(err);
          var error = {};
          if (typeof(err) === 'string') {
            var errParts = err.split('|');
            error = errParts.length > 1 ?
              { message: errParts[1], debug: errParts[0] } :
              { message: err };
          } else {
            error = {
              message: err.message,
              debug: JSON.stringify(err)
            };
          }
          this.setState({
            isAuthenticated: false,
            user: {},
            error: error
          });
        }
        this.setState({
          isUserDetailLoaded: true,
        });
        try{
          var accessToken3 = await this.userAgentApplication.acquireTokenSilent({
            scopes: config.scopes
          });
          //console.log("[MSAL-Access]Token Acquired");
          //console.log(accessToken3);
          if (accessToken3) {
            var groups = await this.getuserGroupss(accessToken3);
            //console.log("[MSAL-Auth]User group information as follows:");
            //console.log(groups);
            var chronosGroups = new Array;
            groups.value.forEach(this.groupFilter);
            chronosGroups = this.state.userGroups;
            chronosGroups.forEach(this.groupPrivilegeUpdater);
            var tText = this.state.topUserGroup + '-' + this._levelExplain(this.state.topUserGroup);
            //console.log("[Emerald]updating tertiary text v");
            //console.log(tText);
            this.setState({
              tertiaryText: tText,
              submitLevel: this._submitLevelCheck(this.state.topUserGroup)
            });
            //console.log("[Emerald]User groups:");
            //console.log(this.state.userGroups);
            //console.log("[Emerald]Top User group:");
            //console.log(this.state.topUserGroup);
          }
        }
        catch(err){
          //console.log("[MSAL-Auth]Avatar retrieve failed");
          //console.log(err);
          var error = {};
          if (typeof(err) === 'string') {
            var errParts = err.split('|');
            error = errParts.length > 1 ?
              { message: errParts[1], debug: errParts[0] } :
              { message: err };
          } else {
            error = {
              message: err.message,
              debug: JSON.stringify(err)
            };
          }
        }
        this.setState({
          loginInProgress: false,
        });
        this.updateIndicator();
        this.state.doGlobalUpdate();
        try{
          var accessToken2 = await this.userAgentApplication.acquireTokenSilent({
            scopes: config.scopes
          });
          //console.log("[MSAL-Access]Token Acquired");
          //console.log(accessToken2);
          if (accessToken2) {
            var avatar = await this.getUserAvatar(accessToken2);
            //console.log("[MSAL-Auth]User avatar information as follows:");
            //console.log(avatar);
            const url = window.URL || window.webkitURL;
            const blobUrl = url.createObjectURL(avatar);
            this.setState({
              avatar: blobUrl,
            });
          }
        }
        catch(err){
          //console.log("[MSAL-Auth]Avatar retrieve to failed");
          //console.log(err);
          var error = {};
          if (typeof(err) === 'string') {
            var errParts = err.split('|');
            error = errParts.length > 1 ?
              { message: errParts[1], debug: errParts[0] } :
              { message: err };
          } else {
            error = {
              message: err.message,
              debug: JSON.stringify(err)
            };
          }
          this.setState({
            avatar: null,
            showUnknownPersonaCoin: true,
          });
        }
        this.setState({
          isDataLoaded: true,
        });
        //console.log("[MUIA]UpdateWorker:")
        //console.log(this.state.doGlobalUpdate);
        //console.log("[MSAL]Info reciver going to sleep");
      }

      groupPrivilegeUpdater  = (value:String,index:any):void => {
        //console.log("[GPRU]trying to update Privileged group:");
        //console.log("[GPRU]"+ value +" => " + this.state.topUserGroup);
        if(value < this.state.topUserGroup){
          this.setState({
            topUserGroup: value,
          })
          //console.log("[GPRU]Success");
        }
        else{
          //console.log("[GPRU]Failed");
        }
      }
      groupFilter = (value:any,index:any):void => {
        if(value.displayName.startsWith("Chronos")){
          var cuGroups = this.state.userGroups;
          cuGroups.push(value.displayName.substr(8));
          this.setState({
            userGroups: cuGroups,
          });
        }
      }
      /*DEPRECATED
      private _submit = (): void => {
        var App = this;
        var target = this.state.chronos.toString() + "/session/login";
        var passwd = document.getElementById("loginPasswordField").value;
        if(passwd === ""){
          alert("Empty password.");
          return;
        }
        var encrypted = md5(passwd);
        axios.post(target, {
          "user": document.getElementById("loginUsernameField").value,
          "passwd": encrypted
        }).then(function (response) {
          if(response.data.result === true){
            App._closeDialog();
          //  App.states.getSessionInfo();
            //this.state.hideDialog = true;
  
          }
          else{
            alert("Wrong password");
          }
        });
      }
    */
    private _showDialog = ():void => {
      this.setState({
        dialogHidden: false,
      });
    }
  
    private _closeDialog = ():void => {
      this.setState({
        dialogHidden: true,
      });
    }
    updateUser = ():void => {
    }
    updateIndicator = ():void => {
      if(this.state.isAuthenticated){
        var status:MessageBarType = this.state.globalStatus;
        if(status === MessageBarType.error){
          this.setState({
                  presence: PersonaPresence.blocked,
              });
              //console.log("[USAR]presence:blocked");
        }
        else{
          this.setState({
                  presence: PersonaPresence.online,
              });
              //console.log("[USAR]presence:online");
        }
      }
      else{
        this.setState({
                presence: PersonaPresence.offline,
        });
        //console.log("[USAR]presence:offline");
      }
    }
    setIndicator = (statement: String):void => {
        if (statement==="online") {
          if(this.state.isAuthenticated){
            this.setState({
                presence: PersonaPresence.online,
            });
          }
          else{
            this.setState({
              presence: PersonaPresence.offline,
            });
          }
        }
        else if(statement==="offline"){
            this.setState({
                presence: PersonaPresence.offline,
            });
        }
        else if(statement==="error"){
            this.setState({
                presence: PersonaPresence.blocked,
            });
        }
    }

    private _submitLevelCheck = (content:String):any => {
      if(content==="D0")return 'X';
      if(content==="C0")return 'C';
      if(content==="C1")return 'C';
      if(content==="A0")return 'A';
      if(content==="A1")return 'A';
    }

    private _levelExplain = (content:String):String => {
      if(content==="D0")return String('仅查看');
      if(content==="C0")return String('普通班级');
      if(content==="C1")return String('普通社团');
      if(content==="A0")return String('系统管理员');
      if(content==="A1")return String('全局管理员');
    }

    render(){
        const {
            username, secondaryText, tertiaryText, optionalText, showUnknownPersonaCoin, imageInitials, presence,
            dialogHidden, avatar, isAuthenticated, isUserDetailLoaded, loginInProgress, topUserGroup, isDataLoaded
            } = this.state;
        return(
            <Stack vertical gap={10} id="UserFunctionGroup">
              <Shimmer customElementsGroup={this._PersonaShimmer()} width={300} isDataLoaded={isUserDetailLoaded}>
                <Persona text={username} 
                         secondaryText={secondaryText} 
                         tertiaryText={"权限-"+tertiaryText}
                         optionalText={optionalText}
                         showUnknownPersonaCoin={!isAuthenticated}
                         imageInitials={imageInitials}
                         presence={presence}
                         size={PersonaSize.size72}
                         imageUrl={avatar}         
                         imageShouldFadeIn={true}
                         imageShouldStartVisible={false}
                ></Persona>
              </Shimmer>
              <Text>您好，{username}</Text>
              <div id="login">
                <PrimaryButton onClick={this.state.isAuthenticated?this.logout:this.login} 
                               text={this.state.isDataLoaded?(this.state.isAuthenticated?"注销":"登录"):"数据载入中..."} 
                               disabled={this.state.loginInProgress | !this.state.isDataLoaded}
                />
              </div>
            </Stack>
        );
    }
    private _PersonaShimmer = (): JSX.Element => {
    return (
      <div style={{ display: 'flex' }}>
        <ShimmerElementsGroup
          shimmerElements={[{ type: ShimmerElementType.circle, height: 40 }, { type: ShimmerElementType.gap, width: 16, height: 40 }]}
        />
        <ShimmerElementsGroup
          flexWrap={true}
          width="100%"
          shimmerElements={[
            { type: ShimmerElementType.line, width: '100%', height: 10, verticalAlign: 'bottom' },
            { type: ShimmerElementType.line, width: '90%', height: 8 },
            { type: ShimmerElementType.gap, width: '10%', height: 20 }
          ]}
        />
      </div>
    );
  };
}