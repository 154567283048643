import React from "react"
import axios from "axios"
import { Stack, Text, Link, FontWeights, Shimmer, IMessageBarProps } from "office-ui-fabric-react"
import { MessageBar, MessageBarType, MessageBarButton } from "office-ui-fabric-react"
import { IStyle, Label } from "office-ui-fabric-react"
import { grid } from "office-ui-fabric-react"
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { IPersonaSharedProps, Persona, PersonaSize, PersonaPresence} from "office-ui-fabric-react"
import { IScheme } from 'office-ui-fabric-react' 
import { DefaultButton, IButtonProps, PrimaryButton} from "office-ui-fabric-react"
import { Icon } from "office-ui-fabric-react"

export default class ServerInfoArea extends React.Component<{}> {
    constructor(props){
        super(props);
        this.state={
            chronos: props.chronos,
            chronosVersion: props.chronosVersion,
            emeraldVersion: props.emeraldVersion,
            apiStatuts: "unknown",
            waiting: false,
            globalNotifyStatus: props.globalNotifyStatus,
            globalNotifyContent: props.globalNotifyContent,
            _refresh: props._refresh,
            doGlobalUpdate: props.doGlobalUpdate,
            errorOccured: props.errorOccured
        };
    }

    componentDidMount(){
      this.update();
    }

    update = ():void => {
        this.getApiStatus();
        this._updateVersion();
    }

    private _updateGlobalStatus = (status:MessageBarType, content:String): void =>{
        this.setState({
            globalNotifyStatus: status,
            globalNotifyContent: content,
        });
        ////console.log("!!");
    }

    updateVersion = (version:String): void => {
        this.setState({
            chronosVersion: version,
        });
    }

    private _updateVersion = (): void => {
                var target = this.state.chronos.toString() + "/api/version";
                var App = this;
                App.setState({
                        versionLoaded: 0,
                        version: ""
                });
        axios.get(target)
                .then(function (response) {
                                App.setState({
                                        versionLoaded: 1,
                                        chronosVersion: response.data.version,
                                });
                                App._updateGlobalStatus();
                                App.updateVersion(response.data.version);
                        })
                        .catch(function (error) {
                                //console.log(error);
                                App.setState({
                                        versionLoaded: -1,
                                });
                                App.state.errorOccured();
                                //App._updateGlobalStatus();
                });
    }

    private getApiStatus = (): void =>{
      var target = this.state.chronos.toString() + "/api/status";
      var App = this;
      App.setState({
              apiStatus: "unknown",
      });
      axios.get(target)
      .then(function (response) {
                      App.setState({
                              apiStatus: response.data.status,
                      });
              })
              .catch(function (error) {
                      App.setState({
                              apiStatus: "unknown",
                      });
                      App.state.errorOccured();
      });
    }

    render(){
        const {
            chronosVersion, emeraldVersion, apiStatus
        } = this.state;
        return(
            <Stack vertical gap={10} id="ServerInfo">
                    <Text varient="medium">
                        服务器信息
                    </Text>
                    <Stack horizontal gap={10} id="chronosStack">
                        <Text variant="medium">
                            Chronos:
                        </Text>
                        <Shimmer isDataLoaded={chronosVersion}>
                            <Text variant="medium">{chronosVersion}</Text>
                        </Shimmer>
                    </Stack>
                    <Stack horizontal gap={10}>
                        <Text variant="medium">
                            Emerald:
                        </Text>
                        <Text variant="medium" id="versionEmerald">
                            {emeraldVersion}
                        </Text>
                    </Stack>
                    <Stack horizontal gap={10}>
                        <Text variant="medium">
                            当前可提交:
                        </Text>
                        <Text variant="medium" id="apiStatus">
                            {apiStatus==="unknown"?<Icon iconName="Warning"/>:
                             apiStatus===true?<Icon iconName="Accept"/>:
                             <Icon iconName="Cancel"/>}
                        </Text>
                    </Stack>
            </Stack>
        );
    }
}